import React, { useEffect, useState } from "react";
import styles from "./Dynamicformgenerator.module.css"
import Taginput from "../taginput/Taginput";
import Employmentinput from "../taginput/Employmentinput";
import Arrayinput from "../taginput/Arrayinput";
import { axiosPrivateCall, apiCall } from "../../constants";
import Locationinput from "../taginput/Locationinput";
import SearchableSelect from "../taginput/Searchableinput";
import Searchinput from "../taginput/Searchinput";
import dataStore from "../../store/store";
import Loadersmall from "../../components/loader/Loadersmall"
import Jdinput from "../taginput/Jdinput";
import FileParse from "../taginput/FileParse";


function DynamicFormGenerator ({ formFields, onInputChange, formData ,errors, setFormData, setErrors, param, handleSubmit }) {

  let genderDropdown = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ];

  const {employee, stakeholders, org_data} = dataStore();

  const[roleDropdown, setRoleDropDown] = useState([]);
  const[statusDropdown, setStatusDropDown] = useState([]);
  const[stakeDropdown, setStakeDropDown] = useState([]);
  const[employeeDropdown, setEmployeeDropDown] = useState([]);

  const[uploading, setUploading] = useState(false);
  const[updating, setUpdating] = useState(false);

  useEffect(() => {

    setRoleDropDown(
      org_data?.org_struct?.roles.map((role) => ({
        value: role,
        label: role
      }))
    );

    setStakeDropDown(
      stakeholders?.map((dataObj) => ({
        value: dataObj._id,
        label: dataObj.company_name
      }))
    );

    setEmployeeDropDown(
      employee.map(dataObj => ({ value: dataObj._id, label: dataObj.user_name }))
    );
    

    if (param) {
      setStatusDropDown(
        param?.map((dataObj) => ({
          value: dataObj.state,
          label: dataObj.state
        }))
      );
    }
  
  },[])

  function uploadHandler(name, e) {
  
    if (
      e.target.files[0]?.type === "application/pdf" ||
      e.target.files[0]?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e.target.files[0]?.type === "application/msword"
    ) {

      setUploading(true);
      
      let files = e.target.files[0];
      let formdata = new FormData();
      formdata.append("file", files);

      apiCall(
        "post",
        `/api/v1/candidate/uploadCandidateResume`,
        formdata,
        {},
        axiosPrivateCall
      ).then((res) => {
        if (res) {
          setFormData((prevState) => {
            return { ...prevState, [name]: res?.document };
          });

          setErrors((prevState) => {
            return { ...prevState, [name]: "" };
          });

          setUploading(false);
        }
      });

    } else {
      setErrors((prevState) => {
        return { ...prevState, [name]: "Invalid Format, Please Select Pdf / Doc / Docx" };
      });
    }
  }


    return (
      <form>
        {formFields.map((field) => (
          <div key={field.name} className={`${styles.form_field}`} >
            

            {(field.type === 'employmentinput' || field.type === 'array' || field.type === 'textarea-jd' || field.type === 'file')  ? null : <label className={`${styles.field_label}`}>{field.label}</label> }
            
            {field.type === 'text' && (
              <input
                className={`${styles.text}`}
                type="text"
                placeholder={field.placeholder}
                value={formData[field?.name] && typeof formData[field?.name] === 'object' ?
                formData[field?.name][`${field?.name}_id`] :
                formData[field?.name]}
                onChange={(e) => onInputChange(field.name, e.target.value)}
              />
            )}

            {field.type === 'textarea' && field.name !== 'remarks' && (
              <textarea
                className={`${styles.textarea}`}
                placeholder={field.placeholder}
                value={formData[field?.name]}
                onChange={(e) => onInputChange(field.name, e.target.value)}
              />
            )}

            {field.type === 'textarea-jd' && (
              // <textarea
              //   className={`${styles.textarea}`}
              //   placeholder={field.placeholder}
              //   value={formData[field?.name]}
              //   onChange={(e) => onInputChange(field.name, e.target.value)}
              // />

              <Jdinput setUpdating={setUpdating} label={field.label} name={field.name} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit}/>
            )}

            {field.type === 'textarea' && field.name === 'remarks' && (
              <textarea
                className={`${styles.textarea}`}
                placeholder={field.placeholder}
                value={formData['progress'].find((obj) => obj.state == formData['current_progress'])?.remarks}
                onChange={(e) => {
                  
                  const currentProgress = formData['current_progress'];
                  const updatedProgress = formData['progress'].map(obj => {
                    if (obj.state == currentProgress) {
                        obj.remarks = e.target.value
                    };
                    
                    
                  });
                  onInputChange(formData['progress'], updatedProgress)
                  console.log(formData['progress'].find((obj) => obj.state == formData['current_progress'])?.remarks ,formData['current_progress'] )
                }}
              />
            )}

            {field.type === 'taginput' && (
              !updating ? <Taginput name={field.name} formData={formData} setFormData={setFormData}/> : null
            )}

            {field.type === 'locationinput' && (
              !updating ? <Locationinput name={field.name} formData={formData} setFormData={setFormData}/> : null
            )}

            {field.type === 'date' && (
              <input
                className={`${styles.date}`}
                placeholder={field.placeholder}
                type="date"
                value={formData[field?.name] && new Date(formData[field?.name])?.toISOString()?.split('T')[0]}
                onChange={(e) => onInputChange(field.name, e.target.value)}
              />
            )}

            {field.type === 'file' && (
              <>
                {/* <input
                className={`${styles.date}`}
                placeholder={field.placeholder}
                type="file"
                onChange={(e) => uploadHandler(field?.name, e)}
                />  
              
              {uploading && <Loadersmall />} */}

              <FileParse 
                field={field}
                uploadHandler={uploadHandler}
                uploading={uploading}
                setUpdating={setUpdating}
                formData={formData}
                setFormData={setFormData}
              />

              </>
              
            )}


            {field.type === 'employmentinput' && (
              !updating ? <Employmentinput name={field.name} label={field.label} formData={formData} setFormData={setFormData}/> : null
            )}

            {field.type === 'array' && (
              <Arrayinput name={field.name} label={field.label} formData={formData} setFormData={setFormData} edit={field.edit}/>
            )}

            {field.type === 'checkbox' && (
              <input
                type="checkbox"
                value={formData[field?.name]}
                onChange={(e) => onInputChange(field.name, e.target.checked)}
              />
            )}

            {field.type === 'select' && (
              <select
                className={`${styles.select}`}
                onChange={(e) => onInputChange(field.name, e.target.value)}
                value={formData[field?.name]}
                defaultValue={" "}
              >

                <option value=" " disabled >
                  {'Select an option'}
                </option>


                {field?.name === 'gender' &&
                  genderDropdown.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}

                {field?.name === 'user_role' &&
                  roleDropdown.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}

                {field?.name === 'current_progress' && (formData[field?.name] !== 'CLOSED' ) &&
                  statusDropdown?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}

                {field?.name === 'current_progress' && (formData[field?.name] == 'CLOSED' ) &&
                   (
                    <option value="" disabled >
                      {'CLOSED'}
                    </option>
                )}

              </select>


            )}

            {field.type === 'searchable' && (field?.name === 'assigned_to' || field?.name === 'reports_to' ) && (
              <SearchableSelect options={employeeDropdown} name={field.name} formData={formData} onInputChange={onInputChange}/>
            )}

            {field.type === 'searchable' && (field?.name === 'stake_holder') && (
              <SearchableSelect options={stakeDropdown} name={field.name} formData={formData} onInputChange={onInputChange}/>
            )}

            {field.type === 'searchable' && (field?.name === 'demand') && (
              <Searchinput name={field.name} formData={formData} onInputChange={onInputChange}/>
            )}

            {field.type === 'searchinput' && (field?.name === 'candidate') &&  (
              <Searchinput name={field.name} formData={formData} onInputChange={onInputChange}/>
            )}

            {/* Add more cases for other input types as needed */}
            <div className={styles.error_container}>{errors[field.name]}</div>
          </div>
        ))}
      </form>
    );
  };

export default DynamicFormGenerator;